/* eslint-disable camelcase */
import React from "react";
import _ from "lodash";
import { Grid, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import {useFilterBomb} from '../../hooks/useFilter';

// ----------------------------------------------------------------------
const elementIcon = ['fire.png', 'wood.png', 'thunder.png', 'earth.png', 'water.png'];

export default function FilterElement() {
    const { onChangeElement, element } = useFilterBomb();

    return (
        <Grid dir="ltr" container spacing={0.5} sx={{ml:1.5}}>
            {['Fire', 'Wood', 'Thunder', 'Earth', 'Water'].map((mode, index) => (
                <Grid key={mode} item xs={6}>
                    <FormGroup>
                        <FormControlLabel control={
                            <>
                                <Checkbox checked={element[index]} name={index}
                                    onChange={(event) => {
                                        let ctn = 0;
                                        _.forEach(element, v => { if (v) ctn += 1; });
                      
                                        //   if (ctn <= 1 && !event.target.checked) return
                      
                                        onChangeElement({
                                            ...element,
                                            [event.target.name]: event.target.checked
                                        })
                                    }}
                                />
                                <img
                                    src={`/icons/element-icon/${elementIcon[index]}`}
                                    alt="element"
                                    width={22} height={22}
                                    loading="lazy"
                                />
                            </>
                        } label={<Typography variant="body2" sx={{ ml: 0.5 }} > {mode} </Typography>} />
                    </FormGroup>
                </Grid>
            ))}
        </Grid>
    );
}
