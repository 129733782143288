/* eslint-disable camelcase */

import Web3 from 'web3';
import { PANCAKE_SWAP_ABI, TOKEN_ABI } from '../contracts/pancake';
import {
    NETWORK_CONFIG,
    BUSD_CONTRACT_ADDRESS,
} from './constants';

const PANCAKE_SWAP_CONTRACT_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const BNB_CONTRACT_ADDRESS = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

async function calcSell(tokensToSell, tokenAddres) {
    const web3 = new Web3(NETWORK_CONFIG[0].rpcUrls[0]);

    const tokenRouter = await new web3.eth.Contract(TOKEN_ABI, tokenAddres);
    const tokenDecimals = await tokenRouter.methods.decimals().call();
    
    tokensToSell = setDecimals(tokensToSell, tokenDecimals);

    let amountOut;
    try {
        const router = await new web3.eth.Contract(PANCAKE_SWAP_ABI, PANCAKE_SWAP_CONTRACT_ADDRESS);
        amountOut = await router.methods.getAmountsOut(tokensToSell, [tokenAddres, BNB_CONTRACT_ADDRESS]).call();
        amountOut = web3.utils.fromWei(amountOut[1]);
    } catch (error) { console.log(error) }
    
    if (!amountOut) return 0;
    return amountOut;
}

async function calcBNBPrice() {
    const web3 = new Web3(NETWORK_CONFIG[0].rpcUrls[0]);

    // const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //BNB
    // const USDTokenAddress = "0x55d398326f99059fF775485246999027B3197955" // USDT
    
    const bnbToSell = web3.utils.toWei("1", "ether");
    let amountOut;

    try {
        const router = await new web3.eth.Contract(PANCAKE_SWAP_ABI, PANCAKE_SWAP_CONTRACT_ADDRESS);
        amountOut = await router.methods.getAmountsOut(bnbToSell, [BNB_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS]).call();
        amountOut = web3.utils.fromWei(amountOut[1]);
    } catch (error) { console.log(error) }
    if (!amountOut) return 0;
    return amountOut;
}

function setDecimals( number, decimals ){
    number = number.toString();
    const numberAbs = number.split('.')[0]
    let numberDecimals = number.split('.')[1] ? number.split('.')[1] : '';
    while( numberDecimals.length < decimals ){
        numberDecimals += "0";
    }
    return numberAbs + numberDecimals;
}
// https://api.pancakeswap.info/api/v2/tokens/0x2bad52989afc714c653da8e5c47bf794a8f7b11d


export async function getTokenPriceUSD2(addr) { 
    const response = await fetch('https://api.pancakeswap.info/api/v2/tokens/0x2bad52989afc714c653da8e5c47bf794a8f7b11d');
    const ret = await response.text()
    const jsonP = JSON.parse(ret);
    return jsonP && jsonP.data && jsonP.data.price ? jsonP.data.price : 0;
}

export async function getTokenPriceUSD(addr) {
    const tokenAddres = addr; 

    const bnbPrice = await calcBNBPrice();
    console.log(`CURRENT BNB PRICE: ${bnbPrice}`);

    const tokens_to_sell = 1; 
    const priceInBnb = await calcSell(tokens_to_sell, tokenAddres) / tokens_to_sell; 
    
    console.log(`CURRENT TOKEN VALUE IN USD: ${priceInBnb * bnbPrice}`); 
    
    return priceInBnb*bnbPrice
};



