import './style.css'
import { useState } from 'react';
import { Stack, Box } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTube from 'react-youtube';
import ButtonAnimate from "../animate/ButtonAnimate";
import HelpButton from './HelpButton';
import { useDialogs } from '../../hooks/useDialogs';
import useWindowSize from '../../hooks/useWindowSize';

// //
export default function Helps(props) {
    const { noti, onOpen } = useDialogs();
    const size = useWindowSize();
    // console.log(size)
    
    return (
        <HelpButton sx={{ p: 1 }} style={{ position: 'fixed', bottom: 30 , right: 10, zIndex: 999 }}>
            <Stack direction="column" alignItems="left" spacing={0.5} sx={{ p: 1 }}>
                {/* <ButtonAnimate fullWidth onClick={() => {
                    noti('FAQ Video',
                        <YouTube videoId="VXdN7fLgUrY" opts={{
                            width: size.width > 667 ? 553 : size.width-80,
                            playerVars: { autoplay: 1, },
                        }} />
                    );
                }}> FAQ. Stake MTB and Buy Box with BUSD </ButtonAnimate> */}
                {/* <ButtonAnimate fullWidth > FAQ. Trade Box wiht MTB </ButtonAnimate> */}
                <ButtonAnimate fullWidth href="https://t.me/metabombglobal" target="_blank" startIcon={<TelegramIcon />} > Join Telegram </ButtonAnimate>          
            </Stack>
        </HelpButton>
    );
}