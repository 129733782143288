/* eslint-disable camelcase */
import React from "react";
import _ from "lodash";
import { Grid, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import {useFilterBomb} from '../../hooks/useFilter';

// ----------------------------------------------------------------------
const skillIcon = ['skill-1.png', 'skill-2.png', 'skill-3.png', 'skill-4.png', 'skill-5.png', 'skill-6.png', 'skill-7.png',];

export default function FilterElement() {
  const { onChangeSkill, skill } = useFilterBomb();

  return (
    <Grid dir="ltr" container spacing={0.5} sx={{ml:1.5}}>
      {['Skill 1', 'Skill 2', 'Skill 3', 'Skill 4', 'Skill 5', 'Skill 6', 'Skill 7'].map((mode, index) => (
        <Grid key={mode} item xs={6}>
          <FormGroup>
            <FormControlLabel control={
              <>
                <Checkbox checked={skill[index]} name={index}
                  onChange={(event) => {
                    let ctn = 0;
                    _.forEach(skill, v => { if (v) ctn += 1; });
                    // if (ctn <= 1 && !event.target.checked) return
                    onChangeSkill({
                      ...skill,
                      [event.target.name]: event.target.checked
                    })
                  }}
                />
                <img style={{ borderRadius: 16 }}
                  src={`/icons/skill-icon/${skillIcon[index]}`}
                  alt="skill"
                  width={32} height={32}
                  loading="lazy"
                />
              </>
            } label={<Typography variant="body2" sx={{ ml: 0.5 }} > {mode} </Typography>} />
            
          </FormGroup>
        </Grid>
      ))}
    </Grid>
  );
}
