/* eslint-disable camelcase */
import React from "react";
import _ from "lodash";
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import {useFilterHero} from '../../hooks/useFilter';

// ----------------------------------------------------------------------

const classIcon = ['class-warrior.png', 'class-assassin.png', 'class-mage.png', 'class-support.png', 'class-ranger.png']

export default function FilterClass() {
  const { class_hero, onChangeClass } = useFilterHero();

  return (
    <Grid dir="ltr" container spacing={0.5} sx={{ml:1.5}}>
      {['Warrior', 'Assassin', 'Mage', 'Support', 'Ranger'].map((mode, index) => (
        <Grid key={mode} item xs={6}>
          <FormGroup>
            <FormControlLabel control={
              <>
                <Checkbox checked={class_hero[index]} name={index}
                  onChange={(event) => {
                    let ctn = 0;
                    _.forEach(class_hero, v => { if (v) ctn += 1; });

                    // if (ctn <= 1 && !event.target.checked) return

                    onChangeClass({
                      // eslint-disable-next-line camelcase
                      ...class_hero,
                      [event.target.name]: event.target.checked
                    })
                  }}
                />
                <img
                  src={`/icons/${classIcon[index]}`}
                  alt="class"
                  width={22} height={22}
                  loading="lazy"
                />
              </>
            } label={mode} />
          </FormGroup>
        </Grid>
      ))}
    </Grid>
  );
}
