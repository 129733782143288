/* eslint-disable camelcase */
import * as React from 'react';
import { Grid, Typography, Stack, Slider } from '@mui/material';
import {useFilterHero} from '../../hooks/useFilter';

// ----------------------------------------------------------------------

function valuetext(value) {
  return `${value}°C`;
}

function calcStatsBonus(rarity, level) {
  const vl = [
    [1,1,1,1,1,1],
    [1,2,2,2,2,2],
    [1,2,3,3,3,3],
    [1,2,3,4,4,4],
    [1,2,3,4,5,5],
    [1,2,3,4,5,6],
  ]
  return level === 0 ? 0 : vl[rarity][level];
}

export default function FilterStats() {
  const {
    power, health, speed, stamina, bomb_num, bomb_range,
    onChangePower,
    onChangeHealth,
    onChangeSpeed,
    onChangeStamina,
    onChangeBombNum,
    onChangeBombRange
  } = useFilterHero();

  const [value, setValue] = React.useState({
    Power: power,
    Health: health,
    Speed: speed,
    Stamina: stamina,
    Bomb_Num: bomb_num,
    Bomb_Range: bomb_range
  });

  const AttrIcon = [
    'stats-power.svg',
    'stats-health.svg',
    'stats-speed.svg',
    'stats-stamina.svg',
    'stats-bombnum.svg',
    'stats-bombrange.svg']

  return (
    <Grid dir="ltr" container spacing={0.5} sx={{mx:0}}>
      {['Power', 'Health', 'Speed', 'Stamina', 'Bomb_Num', 'Bomb_Range',].map((stats, i) => (
        <Grid key={stats} item xs={12} sx={{ mr: 2 }}>
          <Stack direction="column" alignItems="left" justifyContent="start" >
            <Stack direction="row" alignItems="left" justifyContent="start" >
              <img
                src={`/icons/stats-icon/${AttrIcon[i]}`}
                alt="class"
                width={20} height={20}
                loading="lazy" />
              <Typography variant="subtitle2" sx={{ ml: .5 }} color="textSecondary">{stats} :</Typography>
              <Typography variant="subtitle2" sx={{ ml: .5 }} >{`[${value[stats][0]} - ${value[stats][1]}]`}</Typography>
            </Stack>
            <Slider
              name={stats}
              aria-label="Temperature"
              defaultValue={1}
              value={value[stats]}
              onChange={(event, newValue) => {
                setValue({
                  ...value,
                  [event.target.name]: newValue
                });
                switch (event.target.name) {
                  case 'Power':
                    onChangePower(newValue)
                    break;
                  case 'Health':
                    onChangeHealth(newValue)
                    break;
                  case 'Speed':
                    onChangeSpeed(newValue)
                    break;
                  case 'Stamina':
                    onChangeStamina(newValue)
                    break;
                  case 'Bomb_Num':
                    onChangeBombNum(newValue)
                    break;
                  case 'Bomb_Range':
                    onChangeBombRange(newValue)
                    break;
                  default:
                    break;
                }
              }}
              // getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={i === 4 || i === 5 ? 4 : 18}
            />
          </Stack>
            
        </Grid>
      ))}
    </Grid>
  );
}