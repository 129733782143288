import PropTypes from 'prop-types';
import { createContext } from 'react';
// hooks
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultFilter = {
    f5:0,
    level: [false, false, false, false, false],
    page: 1,
    count: 18,
    sort: 0,
    sort_type: 3,
    totalRow: 0,
    forSale: 2,
}

const initialState = {
    ...defaultFilter,
    onf5: () => { },
    onChangeLevel: () => { },
    onChangePage: () => { },
    onChangeCount: () => { },
    onChangeSort: () => { },
    onChangeSortType: () => { },
    onChangeSale: () => { },
    onChangeTotalRow: () => { },
    onReset: () => { }
};

const FilterHouseContext = createContext(initialState);

// ----------------------------------------------------------------------

FilterHouseProvider.propTypes = {
  children: PropTypes.node,
};

function FilterHouseProvider({ children }) {

    const [filterSetting, setFilterSetting] = useMemoryState('filterHouseSetting', {
        f5:initialState.f5,
        level: initialState.level,
        page: initialState.page,
        count: initialState.count,
        sort: initialState.sort,
        sort_type: initialState.sort_type,
        totalRow: initialState.totalRow,
        forSale: initialState.forSale,
    });

    const onReset = () => {
        setFilterSetting({
            ...filterSetting,
            f5: initialState.f5,
            level: initialState.level,
            // page: initialState.page,
            count: initialState.count,
            sort: initialState.sort,
            // sort_type: initialState.sort_type,
            forSale: initialState.forSale,
        });
    };

    const onf5 = (state) => {
        setFilterSetting({
            ...filterSetting,
            f5:state,
        });
    };

    const onChangeLevel = (state) => {
        setFilterSetting({
            ...filterSetting,
            level: state,
            page:1
        });
    };

    const onChangeCount = (state) => {
        setFilterSetting({
            ...filterSetting,
            count: state,
        });
    };
    
    const onChangePage = (state) => {
        setFilterSetting({
            ...filterSetting,
            page: state,
        });
    }
        
    const onChangeSort = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort: state,
            
        });
    };
    
    const onChangeSortType = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort_type: state,
            
        });
    };

    const onChangeSale = (state) => {
        setFilterSetting({
            ...filterSetting,
            forSale: state,
            page:1
        });
    };

    const onChangeTotalRow = (state) => {
        setFilterSetting({
            ...filterSetting,
            totalRow: state,
        });
    };

    return (
        <FilterHouseContext.Provider
            value={{
                ...filterSetting,
                onf5,
                onChangeLevel,
                onChangePage,
                onChangeCount,
                onChangeSort,
                onChangeSortType,
                onChangeSale,
                onChangeTotalRow,
                onReset
            }}
        >
            {children}
        </FilterHouseContext.Provider>
    );
}

export { FilterHouseProvider, FilterHouseContext }
