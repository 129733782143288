import { FormatColorResetOutlined } from "@mui/icons-material";

export const release = {
    events: {
        rank_mtb: false,
        hero_heroes: true,
        bomb_boxes: false,
        mystery_boxes: true,
    },
    trade: {
        boxes: true,
        heroes: true,
        bombs: true,
        bricks: true,
        houses: true,
        hotels: false,
    },
    games: false,
    // {
    //     spinner: false,
    // },
    account: {
        claim: true,
        inventory: true,
        activities: true,
        settings: true,
    }
}
