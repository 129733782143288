import { React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getMTBBalance } from '../../../utils/cryptoWallet';
import { ButtonAnimate } from '../../../components/animate';
import { useMemoryState } from "../../../utils/memoryState";
import sessionData from '../../../sessionData';
import { NETWORK_CONFIG } from '../../../utils/constants';

const balanceTicker = '';
// ----------------------------------------------------------------------
    
export default function MTBPopover() {
  const [profile, setProfile] = useMemoryState('profile', null)
  const [open, setOpen] = useState(null);
  const [balance, updateBalance] = useState(null);

  useEffect(
    () => {
      const getbl = async () => {
        if (!sessionData.myAddress) return
        const mtbBlance = await getMTBBalance()
        const balanceStr = parseFloat(parseFloat(mtbBlance).toFixed(0))
        updateBalance(`${balanceStr} ${balanceTicker}`)
        // setProfile({ ...profile, mtb_num: balanceStr })
      }
      getbl()
    }
    , [profile, setProfile]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  
  return (
    <>
      {
        sessionData.myAddress && <ButtonAnimate
          href={`${NETWORK_CONFIG[0].blockExplorerUrls  }/address/${  sessionData.myAddress}`} target="_blank"
          color='warning'
          onClick={handleOpen}
          startIcon={<img src={`/icons/mtb-token.png`} alt="i" width={25} height={25} loading="lazy" />}
        >
          <NumberFormat value={balance} displayType={'text'} thousandSeparator />
        </ButtonAnimate>
      }
    </>
  );
}
