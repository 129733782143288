
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@mui/material';
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import { NETWORK_VERSION, METAMASK_PROVIDER } from '../../utils/constants';
import { getJWT, getNetworkVersion, switchToBSCNetwork, updateWeb3Provider } from '../../utils/cryptoWallet';

// import { isMobileBrowser } from '../../utils/utils';

export default function LoginMetaMaskButton() {

    // const isMobile = isMobileBrowser()
    const [open, setOpen] = React.useState(false)
    const handleClose = () => setOpen(false);

    const handleChangeNetwork = () => {
        setOpen(false);
        switchToBSCNetwork()
    } 
    
    const handleClickConnect = () => {
        
        if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
            const onboarding = new MetaMaskOnboarding();
            onboarding.startOnboarding();
        } else {
            updateWeb3Provider(METAMASK_PROVIDER)
            
            const networkVersion = Number(getNetworkVersion());

            // eslint-disable-next-line eqeqeq
            if (networkVersion != NETWORK_VERSION) {
                setOpen(true);
                return
            }
            getJWT()
        }
    }
    return (
        <>
            <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary" 
                startIcon={<img alt='metamask' height={32} src="/icons/metamask.png"/>}
                onClick={handleClickConnect}>
                MetaMask
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ mb: 3 }} id="alert-dialog-title"> Use Binance Smart Chain ! </DialogTitle>
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please change your MetaMask network to &nbsp;
                        <Link href="https://docs.binance.org/smart-chain/wallet/metamask.html" target="_blank">
                            Binance Smart Chain
                        </Link> to Connect!
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleChangeNetwork} variant="contained" >
                        Change to BSC
                    </Button>
                    <Button onClick={handleClose} variant="outlined" >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}




