import { useState } from 'react';
import { Box, Divider, Typography, Stack, MenuItem} from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
import { linkToMarket } from '../../../utils/link';
import sessionData from '../../../sessionData';
import MenuPopover from '../../../components/MenuPopover';
import { ButtonAnimate } from '../../../components/animate';
import ConnectWalletBox from '../../../components/loginWallets/ConnectWalletBox'
import { makeAddr } from '../../../utils/utils';
import { getWeb3, getProviderType } from '../../../utils/cryptoWallet';
import {WALLETCONNECT_PROVIDER } from '../../../utils/constants';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    // eslint-disable-next-line eqeqeq
    if (getProviderType() == WALLETCONNECT_PROVIDER) {
      const web3 = getWeb3()
      const provider = web3.currentProvider;
      await provider.disconnect()
    }
    sessionData.logOut()
    window.location = linkToMarket
  }

  return (
    <>
      {
        sessionData.myAddress && sessionData.userId ?
          <ButtonAnimate variant="outlined" onClick={handleOpen}>
            <Typography variant="button" noWrap > {makeAddr(sessionData.myAddress)} </Typography>
          </ButtonAnimate> :
          <ConnectWalletBox component={<ButtonAnimate variant="contained"> CONNECT WALLET</ButtonAnimate>} />
      }
      {
        sessionData.myAddress && sessionData.userId &&
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            p: 0, mt: 1.5, ml: 0.75,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          }}>
          {/* <MenuItem sx={{ m: 1 }} onClick={()=>{}}>MyBox</MenuItem>  
          <Divider sx={{ borderStyle: 'normal' }} /> */}
          <MenuItem sx={{ m: 1 }} onClick={handleLogout}>Logout</MenuItem>
        </MenuPopover>
      }
    </>
  );
}