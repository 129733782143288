import React, { useEffect, useCallback } from 'react';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { Button, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import jwtDecode from 'jwt-decode';
import Router from './routes';
import ThemeProvider from './theme';
import sessionData from './sessionData';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import FilterNFT from './components/filter';
import { DialogsProvider } from './contexts/DialogsContext';
import { client } from './utils/apollo';
import history from './utils/myHistory';
import { ChartStyle } from './components/chart';
import Helps from './components/helps';
import MarketPool from './pools/MarketPool'
import { NETWORK_VERSION, WALLETCONNECT_PROVIDER, RECAPTCHA_SITE_KEY } from './utils/constants';
import { getNetworkVersion, getProviderType, getWeb3, initWallet, switchToBSCNetwork, getMTBBalance } from './utils/cryptoWallet';
import { AccountProvider } from './contexts/AccountContext';

import './App.css';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: "#229A16 !important",
    borderRadius: "13px !important"
  },
  error: {
    backgroundColor: "#B72136 !important",
    borderRadius: "13px !important",
    color: "#fff !important",
  },
  warning: {
    backgroundColor: "#B78103 !important",
    borderRadius: "13px !important",
    color: "#fff !important",
  },
  info: {
    backgroundColor: "#0C53B7 !important",
    borderRadius: "13px !important",
    color: "#fff !important",
  },
  
}));

const notistackRef = React.createRef();
const onClickDismiss = key => () => notistackRef.current.closeSnackbar(key);

export default function App() {
  const classes = useStyles();

  useEffect(() => {
    sessionData.getSessionData()
    initWallet()
    if (sessionData.userId) {
      
      // eslint-disable-next-line eqeqeq
      if (getNetworkVersion() != NETWORK_VERSION) {
        switchToBSCNetwork(history)
      } else {
        // update lại address và request quyền
        // requestAdressAndApproveToken()
      }
    }
  }, []);

  useEffect(() => {
    const token = sessionData.jwToken;
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        const { exp } = decodedToken;
        const noww = Math.floor(Date.now() / 1000);
        if (noww > exp) {
          console.log('Outttttttttttttttttttttttttttttttttttttttttttttttttttt')
          sessionData.logOut()
        }
      }
    }
  }, []);
    
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ApolloProvider client={client}>
          <AccountProvider>
            <DialogsProvider>
              <SnackbarProvider
                classes={{
                  variantSuccess: classes.success,
                  variantError: classes.error,
                  variantInfo: classes.info,
                  variantWarning: classes.warning,
                }}
                ref={notistackRef}
                action={(key) => (
                  <Button color='inherit' onClick={onClickDismiss(key)} size="small" variant="outlined">
                    Dismiss
                  </Button>
                )}
                // content={
                //   (key, message) => {
                //     if (typeof message === 'string' || message instanceof String) return undefined
                //     return (<MySnackMessage id={key} message={message} />)
                //   }}
                maxSnack={3} preventDuplicate>
                <RtlLayout>
                  <MotionLazyContainer>
                    <ProgressBarStyle />
                    <MarketPool />
                    <FilterNFT />
                    <ChartStyle />
                    <ScrollToTop />
                    <Router />
                  </MotionLazyContainer>
                </RtlLayout>
              </SnackbarProvider>
              <Helps />
              <Stack alignItems="end" justifyContent="center" sx={{ p: 2 }} >
                <Typography color="textSecondary" variant="body2">
                  This site is protected by reCAPTCHA and the Google
                  <a style={{ color: 'aquamarine' }} href="https://policies.google.com/privacy"> Privacy Policy</a> and
                  <a style={{ color: 'aquamarine' }} href="https://policies.google.com/terms"> Terms of Service</a> apply.
                </Typography>
              </Stack>
            </DialogsProvider>
          </AccountProvider>
        </ApolloProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}