import PropTypes from 'prop-types';
import { createContext } from 'react';
// hooks
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultFilter = {
    f5:0,
    rarity: [false, false, false, false, false, false],
    class_hero: [false, false, false, false, false],
    power: [1, 18],
    speed: [1, 18],
    health: [1, 18],
    stamina: [1, 18],
    bomb_num: [1, 4],
    bomb_range: [1, 4],
    level: [0, 6],
    page: 1,
    count: 18,
    sort: 0,
    sort_type: 1,
    totalRow: 0,
    forSale: 1,
}

const initialState = {
    ...defaultFilter,
    onf5:()=>{},
    onChangeRarity: () => { },
    onChangeClass: () => { },
    onChangePower: () => { },
    onChangeSpeed: () => { },
    onChangeHealth: () => { },
    onChangeStamina: () => { },
    onChangeBombNum: () => { },
    onChangeBombRange: () => { },
    onChangeLevel: () => { },
    onChangePage: () => { },
    onChangeCount: () => { },
    onChangeSort: () => { },
    onChangeSortType: () => { },
    onChangeSale: () => { },
    onChangeTotalRow: () => { },
    onReset: () => {}
};

const FilterHeroContext = createContext(initialState);

// ----------------------------------------------------------------------

FilterHeroProvider.propTypes = {
  children: PropTypes.node,
};

function FilterHeroProvider({ children }) {

    const [filterSetting, setFilterSetting] = useMemoryState('filterHeroSetting', {
        f5:initialState.f5,
        rarity: initialState.rarity,
        class_hero: initialState.class_hero,
        power: initialState.power,
        speed: initialState.speed,
        health: initialState.health,
        stamina: initialState.stamina,
        bomb_num: initialState.bomb_num,
        bomb_range: initialState.bomb_range,
        level: initialState.level,
        page: initialState.page,
        count: initialState.count,
        sort: initialState.sort,
        sort_type: initialState.sort_type,
        totalRow: initialState.totalRow,
        forSale: initialState.forSale,
    });

    const onReset = () => {
        setFilterSetting({
            ...filterSetting,
            f5: initialState.f5,
            rarity: initialState.rarity,
            class_hero: initialState.class_hero,
            power: initialState.power,
            speed: initialState.speed,
            health: initialState.health,
            stamina: initialState.stamina,
            bomb_num: initialState.bomb_num,
            bomb_range: initialState.bomb_range,
            level: initialState.level,
            // page: initialState.page,
            count: initialState.count,
            sort: initialState.sort,
            // sort_type: initialState.sort_type,
            forSale: initialState.forSale,
        });
    };

    const onf5 = (state) => {
        setFilterSetting({
            ...filterSetting,
            f5:state,
        });
    };

    const onChangeRarity = (state) => {
        setFilterSetting({
            ...filterSetting,
            rarity: state,
            page:1
        });
    };

    const onChangeClass = (state) => {
        setFilterSetting({
            ...filterSetting,
            class_hero: state,
            page:1
        });
    };
    
    const onChangePower = (state) => {
        setFilterSetting({
            ...filterSetting,
            power: state,
            page:1
        });
    };

    const onChangeSpeed = (state) => {
        setFilterSetting({
            ...filterSetting,
            speed: state,
            page:1
        });
    };

    const onChangeHealth = (state) => {
        setFilterSetting({
            ...filterSetting,
            health: state,
            page:1
        });
    };

    const onChangeStamina = (state) => {
        setFilterSetting({
            ...filterSetting,
            stamina: state,
            page:1
        });
    };

    const onChangeBombNum = (state) => {
        setFilterSetting({
            ...filterSetting,
            bomb_num: state,
            page:1
        });
    };

    const onChangeBombRange = (state) => {
        setFilterSetting({
            ...filterSetting,
            bomb_range: state,
            page:1
        });
    };

    const onChangeLevel = (state) => {
        setFilterSetting({
            ...filterSetting,
            level: state,
            page:1
        });
    };

    const onChangeCount = (state) => {
        setFilterSetting({
            ...filterSetting,
            count: state,
        });
    };
    
    const onChangePage = (state) => {
        setFilterSetting({
            ...filterSetting,
            page: state,
        });
    }
        
    const onChangeSort = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort: state,
            
        });
    };
    
    const onChangeSortType = (state) => {
        setFilterSetting({
            ...filterSetting,
            sort_type: state,
            
        });
    };

    const onChangeSale = (state) => {
        setFilterSetting({
            ...filterSetting,
            forSale: state,
            page:1
        });
    };

    const onChangeTotalRow = (state) => {
        setFilterSetting({
            ...filterSetting,
            totalRow: state,
        });
    };

    return (
        <FilterHeroContext.Provider
            value={{
                ...filterSetting,
                onf5,
                onChangeRarity,
                onChangeClass,
                onChangePower,
                onChangeSpeed,
                onChangeHealth,
                onChangeStamina,
                onChangeBombNum,
                onChangeBombRange,
                onChangeLevel,
                onChangePage,
                onChangeCount,
                onChangeSort,
                onChangeSortType,
                onChangeSale,
                onChangeTotalRow,
                onReset
            }}
        >
            {children}
        </FilterHeroContext.Provider>
    );
}

export { FilterHeroProvider, FilterHeroContext }
