// MAINNET !!!
export const MTB_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"INITIAL_SUPPLY",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         }
      ],
      "name":"allowance",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"bpContract",
      "outputs":[
         {
            "internalType":"contract IBPContract",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"bpDisabledForever",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"bpEnabled",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"burnFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"decimals",
      "outputs":[
         {
            "internalType":"uint8",
            "name":"",
            "type":"uint8"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"subtractedValue",
            "type":"uint256"
         }
      ],
      "name":"decreaseAllowance",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"addedValue",
            "type":"uint256"
         }
      ],
      "name":"increaseAllowance",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"addr",
            "type":"address"
         }
      ],
      "name":"setBPContract",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"setBPDisableForever",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bool",
            "name":"enabled",
            "type":"bool"
         }
      ],
      "name":"setBPEnabled",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"transfer",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const BUSD_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"_decimals",
      "outputs":[
         {
            "internalType":"uint8",
            "name":"",
            "type":"uint8"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"_name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"_symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         }
      ],
      "name":"allowance",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"decimals",
      "outputs":[
         {
            "internalType":"uint8",
            "name":"",
            "type":"uint8"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"subtractedValue",
            "type":"uint256"
         }
      ],
      "name":"decreaseAllowance",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"getOwner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"spender",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"addedValue",
            "type":"uint256"
         }
      ],
      "name":"increaseAllowance",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"mint",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":true,
      "inputs":[
         
      ],
      "name":"totalSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "payable":false,
      "stateMutability":"view",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"transfer",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"sender",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "constant":false,
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

export const FAUCET_CONTRACT_MTB_ABI = [
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_tokenInstance",
            "type":"address"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"allowedToWithdraw",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestTokens",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenAmount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenInstance",
      "outputs":[
         {
            "internalType":"contract ERC20",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"waitTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
]

export const FAUCET_CONTRACT_BUSD_ABI = [
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_tokenInstance",
            "type":"address"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"allowedToWithdraw",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestTokens",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenAmount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenInstance",
      "outputs":[
         {
            "internalType":"contract ERC20",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"waitTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
]

export const NFT_HERO_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackList",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"boxType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"totalFee",
            "type":"uint256"
         }
      ],
      "name":"BuyHero",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"level",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"nonce",
            "type":"uint256"
         }
      ],
      "name":"Fusion",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "name":"HeroBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"HeroDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "name":"HeroListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"HeroTransfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"level",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"nonce",
            "type":"uint256"
         }
      ],
      "name":"LevelUp",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"boxType",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"MintHero",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"blackListAddress",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"typeBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"buyHero",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleHero",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"level1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"level2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"fusion",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"fusionFees",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getCurrentCounter",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getFusionFeeByRarity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getLevelUpFeeByRarity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getMaxLevelByRarity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"pure",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"heroOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"level1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"level2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"levelUp",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"levelUpFees",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"boxId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"boxType",
            "type":"uint256"
         }
      ],
      "name":"openBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"age",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"removeFromBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"setBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"add",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"boxType",
            "type":"uint256"
         }
      ],
      "name":"setBoxAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setBuyToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_fusionFees",
            "type":"uint256[]"
         }
      ],
      "name":"setFusionFees",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_levelUpFees",
            "type":"uint256[]"
         }
      ],
      "name":"setLevelUpFees",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minPrice",
            "type":"uint256"
         }
      ],
      "name":"setMinSalePrice",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"typeBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"mintFee",
            "type":"uint256"
         }
      ],
      "name":"setMintFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenOfOwnerByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

export const NFT_BOMB_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackList",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "name":"BombBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"BombDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         }
      ],
      "name":"BombExchange",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"fusionSuccess",
            "type":"bool"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"nonce",
            "type":"uint256"
         }
      ],
      "name":"BombFusion",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "name":"BombListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"BombTransfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"boxType",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"MintBomb",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"boxes",
            "type":"uint256[]"
         }
      ],
      "name":"batchOpenBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"blackListAddress",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"bombOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"brickFusionFees",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleBomb",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"exchange",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"fusion",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"fusionFees",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"tokenId2",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"rarity2",
            "type":"uint256"
         }
      ],
      "name":"fusionTest",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getBrickFusionFeeByRarity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getCurrentCounter",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getFusionFeeByRarity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"rarity",
            "type":"uint256"
         }
      ],
      "name":"getFusionSuccessRate",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"mintTest",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"boxId",
            "type":"uint256"
         }
      ],
      "name":"openBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"removeFromBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"setBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"add",
            "type":"address"
         }
      ],
      "name":"setBoxAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_fusionFees",
            "type":"uint256[]"
         }
      ],
      "name":"setBrickFusionFees",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_fusionFees",
            "type":"uint256[]"
         }
      ],
      "name":"setFusionFees",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_itemAddress",
            "type":"address"
         }
      ],
      "name":"setItemAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setMTBToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"max",
            "type":"uint256"
         }
      ],
      "name":"setMaxOpenBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minPrice",
            "type":"uint256"
         }
      ],
      "name":"setMinSalePrice",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_randomService",
            "type":"address"
         }
      ],
      "name":"setRandomService",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_requestExpired",
            "type":"uint256"
         }
      ],
      "name":"setRequestExpired",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address1",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"_address2",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenOfOwnerByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const STAKE_NORMAL_BOX_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackList",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BuyBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"Claim",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"Deposit",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         }
      ],
      "name":"Withdraw",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"END_TIME",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"STAKE_DAYS",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"boxOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burnToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"deposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"add",
            "type":"address"
         }
      ],
      "name":"getAvailableBoxToSwap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalMintedBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalStaked",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserInfo",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserWithdrawTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getUsers",
      "outputs":[
         {
            "internalType":"address[]",
            "name":"",
            "type":"address[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"addrs",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"mintedTicket",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeTime",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeAmount",
            "type":"uint256[]"
         }
      ],
      "name":"importUser",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxBoxPerUser",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_tokenStakePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_totalBoxSupply",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_pricePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_stakeDays",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_endDate",
            "type":"uint256"
         },
         {
            "internalType":"string",
            "name":"name",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"symbol",
            "type":"string"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxBoxPerUser",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putListBoxOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setBusdAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_claimGap",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_expireTime",
            "type":"uint256"
         }
      ],
      "name":"setExpireTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minClaim",
            "type":"uint256"
         }
      ],
      "name":"setMinClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_requireToken",
            "type":"uint256"
         }
      ],
      "name":"setRequireToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setTokenAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"whitelistAddress",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"allow",
            "type":"bool"
         }
      ],
      "name":"setWhitelistAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"swapBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenStakePerBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdraw",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSwapToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const STAKE_PREMIUM_BOX_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackList",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BuyBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"Claim",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"Deposit",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         }
      ],
      "name":"Withdraw",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"END_TIME",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"STAKE_DAYS",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"boxOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burnToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"deposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"add",
            "type":"address"
         }
      ],
      "name":"getAvailableBoxToSwap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalMintedBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalStaked",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserInfo",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserWithdrawTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getUsers",
      "outputs":[
         {
            "internalType":"address[]",
            "name":"",
            "type":"address[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"addrs",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"mintedTicket",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeTime",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeAmount",
            "type":"uint256[]"
         }
      ],
      "name":"importUser",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxBoxPerUser",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_tokenStakePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_totalBoxSupply",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_pricePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_stakeDays",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_endDate",
            "type":"uint256"
         },
         {
            "internalType":"string",
            "name":"name",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"symbol",
            "type":"string"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxBoxPerUser",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putListBoxOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setBusdAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_claimGap",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_expireTime",
            "type":"uint256"
         }
      ],
      "name":"setExpireTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minClaim",
            "type":"uint256"
         }
      ],
      "name":"setMinClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_requireToken",
            "type":"uint256"
         }
      ],
      "name":"setRequireToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setTokenAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"whitelistAddress",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"allow",
            "type":"bool"
         }
      ],
      "name":"setWhitelistAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"swapBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenStakePerBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdraw",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSwapToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const STAKE_ULTRA_BOX_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackList",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BoxListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BuyBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"Claim",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"Deposit",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         }
      ],
      "name":"Withdraw",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"END_TIME",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"STAKE_DAYS",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"boxOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burnToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"deposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"add",
            "type":"address"
         }
      ],
      "name":"getAvailableBoxToSwap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalMintedBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalStaked",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserInfo",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"getUserWithdrawTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getUsers",
      "outputs":[
         {
            "internalType":"address[]",
            "name":"",
            "type":"address[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"addrs",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"mintedTicket",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeTime",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"lastStakeAmount",
            "type":"uint256[]"
         }
      ],
      "name":"importUser",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxBoxPerUser",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_tokenStakePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_totalBoxSupply",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_pricePerBox",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_stakeDays",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_endDate",
            "type":"uint256"
         },
         {
            "internalType":"string",
            "name":"name",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"symbol",
            "type":"string"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxBoxPerUser",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putListBoxOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setBusdAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_claimGap",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_expireTime",
            "type":"uint256"
         }
      ],
      "name":"setExpireTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minClaim",
            "type":"uint256"
         }
      ],
      "name":"setMinClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_requireToken",
            "type":"uint256"
         }
      ],
      "name":"setRequireToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setTokenAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"whitelistAddress",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"allow",
            "type":"bool"
         }
      ],
      "name":"setWhitelistAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"swapBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tokenStakePerBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdraw",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSwapToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const STAKE_BOMB_BOX_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlackList",
            "type":"bool"
         }
      ],
      "name":"BlackListBombBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BombBoxBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BombBoxDeListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BombBoxListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"BombBoxTransfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"BuyBombBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"Claim",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"boxType",
            "type":"string"
         }
      ],
      "name":"MintBombBox",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blackList",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"blackListAddress",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"boxOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burnToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"buyBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"endTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"getAvailableBoxToSwap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalMintedBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"getUserPurchase",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxBoxPerUser",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_totalBoxSupply",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_pricePerBox",
            "type":"uint256"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxBoxPerUser",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putListBoxOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"removeFromBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"removeFromBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         }
      ],
      "name":"setBlackListAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_claimGap",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setEndTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_expireTime",
            "type":"uint256"
         }
      ],
      "name":"setExpireTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_minClaim",
            "type":"uint256"
         }
      ],
      "name":"setMinClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_requireToken",
            "type":"uint256"
         }
      ],
      "name":"setRequireToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setStartTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setSwapToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"startTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const CLAIM_MTB_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         }
      ],
      "name":"Claim",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"timeStamp",
            "type":"uint256"
         }
      ],
      "name":"TokenDeposit",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"_amount",
            "type":"string"
         },
         {
            "internalType":"uint256",
            "name":"_nonce",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"deposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"lastClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"maxClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minClaim",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_timer",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setDepositAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"startDate",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"endDate",
            "type":"uint256"
         }
      ],
      "name":"setDepositDateRange",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_maxClaim",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"setMaxDeposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"setMaxDepositPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setMinClaim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_timer",
            "type":"uint256"
         }
      ],
      "name":"setRequestExpire",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setTokenAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]

// MAINNET !!!
export const BRICK_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isBlacklisted",
            "type":"bool"
         }
      ],
      "name":"Blacklist",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         }
      ],
      "name":"BuySaleItem",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         }
      ],
      "name":"CancelSale",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         }
      ],
      "name":"ClaimItem",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"ItemBurn",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"timeStamp",
            "type":"uint256"
         }
      ],
      "name":"ItemDeposit",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         }
      ],
      "name":"ItemListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256[]",
            "name":"ids",
            "type":"uint256[]"
         },
         {
            "indexed":false,
            "internalType":"uint256[]",
            "name":"values",
            "type":"uint256[]"
         }
      ],
      "name":"TransferBatch",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"TransferSingle",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"string",
            "name":"value",
            "type":"string"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         }
      ],
      "name":"URI",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"BRICK",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"allowSaleQuantity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"accounts",
            "type":"address[]"
         },
         {
            "internalType":"uint256[]",
            "name":"ids",
            "type":"uint256[]"
         }
      ],
      "name":"balanceOfBatch",
      "outputs":[
         {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"uint256[]",
            "name":"ids",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"values",
            "type":"uint256[]"
         }
      ],
      "name":"burnBatch",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleItem",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"logId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"claim",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"claimGap",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"deposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"saleNo",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         }
      ],
      "name":"editPrice",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"maxClaimQuantity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"minClaimQuantity",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"account",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"mint",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256[]",
            "name":"ids",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"amounts",
            "type":"uint256[]"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"mintBatch",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         },
         {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"",
            "type":"uint256[]"
         },
         {
            "internalType":"bytes",
            "name":"",
            "type":"bytes"
         }
      ],
      "name":"onERC1155BatchReceived",
      "outputs":[
         {
            "internalType":"bytes4",
            "name":"",
            "type":"bytes4"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"",
            "type":"bytes"
         }
      ],
      "name":"onERC1155Received",
      "outputs":[
         {
            "internalType":"bytes4",
            "name":"",
            "type":"bytes4"
         }
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"packageId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256[]",
            "name":"ids",
            "type":"uint256[]"
         },
         {
            "internalType":"uint256[]",
            "name":"amounts",
            "type":"uint256[]"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"safeBatchTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"id",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"saleItems",
      "outputs":[
         {
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"allowQuantity",
            "type":"uint256[]"
         }
      ],
      "name":"setAllowQuantity",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         },
         {
            "internalType":"bool",
            "name":"isBlacklisted",
            "type":"bool"
         }
      ],
      "name":"setBlackList",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_timer",
            "type":"uint256"
         }
      ],
      "name":"setClaimGap",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"startDate",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"endDate",
            "type":"uint256"
         }
      ],
      "name":"setDepositDateRange",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"maxQty",
            "type":"uint256"
         }
      ],
      "name":"setMaxClaimQuantity",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"setMaxDeposit",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"setMaxDepositPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"minQty",
            "type":"uint256"
         }
      ],
      "name":"setMinClaimQuantity",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_value2",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"itemType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"maxQty",
            "type":"uint256"
         }
      ],
      "name":"setTotalClaimQuantityPerDay",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address1",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"_address2",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"newUri",
            "type":"string"
         }
      ],
      "name":"setURI",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"uri",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]


export const STAKE_MYSTERY_BOX_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"poolType",
            "type":"uint256"
         }
      ],
      "name":"Stake",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"poolType",
            "type":"uint256"
         }
      ],
      "name":"Withdraw",
      "type":"event"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"depositPool1",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         }
      ],
      "name":"depositPool2",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getTotalStaked",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool1EndDate",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool1StakeAmount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool1StakeAmountPerBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool1StakeDays",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool1StartDate",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2BoxCount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2EndDate",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2MaxBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2StakeAmount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2StakeAmountPerBox",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2StakeDays",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pool2StartDate",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"endDate",
            "type":"uint256"
         }
      ],
      "name":"setPool1EndDate",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"startDate",
            "type":"uint256"
         }
      ],
      "name":"setPool1StartTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"endDate",
            "type":"uint256"
         }
      ],
      "name":"setPool2EndDate",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"maxBox",
            "type":"uint256"
         }
      ],
      "name":"setPool2MaxBox",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"startDate",
            "type":"uint256"
         }
      ],
      "name":"setPool2StartTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_timer",
            "type":"uint256"
         }
      ],
      "name":"setRequestExpire",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_addr",
            "type":"address"
         }
      ],
      "name":"setTokenAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         },
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"userStaking",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"poolType",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"stakeAmount",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lastStakeDate",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"poolType",
            "type":"uint256"
         }
      ],
      "name":"withdraw",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]


export const NFT_HOUSE_CONTRACT_ABI = [
   {
      "inputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"constructor"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"previousAdmin",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"address",
            "name":"newAdmin",
            "type":"address"
         }
      ],
      "name":"AdminChanged",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"approved",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Approval",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"ApprovalForAll",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"beacon",
            "type":"address"
         }
      ],
      "name":"BeaconUpgraded",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlacklist",
            "type":"bool"
         }
      ],
      "name":"Blacklist",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"bool",
            "name":"isInBlacklist",
            "type":"bool"
         }
      ],
      "name":"BlacklistAddress",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"totalAmount",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"Buy",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"CancelSale",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"buyer",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"seller",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"ItemBought",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"ItemListed",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"ItemTransfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"Mint",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"OwnershipTransferred",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Paused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"validator",
            "type":"address"
         }
      ],
      "name":"SetValidator",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "indexed":true,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"Transfer",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":false,
            "internalType":"address",
            "name":"account",
            "type":"address"
         }
      ],
      "name":"Unpaused",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"user",
            "type":"address"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"uint256",
            "name":"level",
            "type":"uint256"
         },
         {
            "indexed":false,
            "internalType":"string",
            "name":"itemType",
            "type":"string"
         }
      ],
      "name":"Upgrade",
      "type":"event"
   },
   {
      "anonymous":false,
      "inputs":[
         {
            "indexed":true,
            "internalType":"address",
            "name":"implementation",
            "type":"address"
         }
      ],
      "name":"Upgraded",
      "type":"event"
   },
   {
      "inputs":[
         
      ],
      "name":"BRICK",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"approve",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"blacklisted",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "name":"blacklistedAddress",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"burn",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"quantity",
            "type":"uint256"
         }
      ],
      "name":"buy",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"buyPrice",
            "type":"uint256"
         }
      ],
      "name":"buySaleItem",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"cancelSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"endTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"getApproved",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"getCurrentCounter",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"string",
            "name":"name",
            "type":"string"
         },
         {
            "internalType":"string",
            "name":"symbol",
            "type":"string"
         },
         {
            "internalType":"uint256",
            "name":"_price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_maxLevel",
            "type":"uint256"
         }
      ],
      "name":"initialize",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         }
      ],
      "name":"isApprovedForAll",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"itemOnSale",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"levelQtyCount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"listItem",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"level",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"maxLevelQtyCount",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"minSalePrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"name",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"owner",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"ownerOf",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"pause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"paused",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"proxiableUUID",
      "outputs":[
         {
            "internalType":"bytes32",
            "name":"",
            "type":"bytes32"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"price",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"timestamp",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"lockUntil",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_sign",
            "type":"bytes"
         }
      ],
      "name":"putOnSale",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"renounceOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"requestExpire",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         },
         {
            "internalType":"bytes",
            "name":"_data",
            "type":"bytes"
         }
      ],
      "name":"safeTransferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"serviceFee2",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"operator",
            "type":"address"
         },
         {
            "internalType":"bool",
            "name":"approved",
            "type":"bool"
         }
      ],
      "name":"setApprovalForAll",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address[]",
            "name":"_addrs",
            "type":"address[]"
         },
         {
            "internalType":"bool",
            "name":"isBlacklisted",
            "type":"bool"
         }
      ],
      "name":"setBlacklistAddress",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_tokenIds",
            "type":"uint256[]"
         },
         {
            "internalType":"bool",
            "name":"isBlacklisted",
            "type":"bool"
         }
      ],
      "name":"setBlacklistItem",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setBrickContract",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"start",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"end",
            "type":"uint256"
         },
         {
            "internalType":"uint256[]",
            "name":"maxQty",
            "type":"uint256[]"
         }
      ],
      "name":"setEventTime",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address",
            "type":"address"
         }
      ],
      "name":"setMTBFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_saleToken",
            "type":"address"
         }
      ],
      "name":"setSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_value1",
            "type":"uint256"
         },
         {
            "internalType":"uint256",
            "name":"_value2",
            "type":"uint256"
         }
      ],
      "name":"setServiceFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_address1",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"_address2",
            "type":"address"
         }
      ],
      "name":"setTradingFeeReceiver",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_fees",
            "type":"uint256[]"
         }
      ],
      "name":"setUpgradeBrickFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256[]",
            "name":"_fees",
            "type":"uint256[]"
         }
      ],
      "name":"setUpgradeMTBFee",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"_validator",
            "type":"address"
         }
      ],
      "name":"setValidator",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"startTime",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"bytes4",
            "name":"interfaceId",
            "type":"bytes4"
         }
      ],
      "name":"supportsInterface",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"symbol",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"owner",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"index",
            "type":"uint256"
         }
      ],
      "name":"tokenOfOwnerByIndex",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"tokenURI",
      "outputs":[
         {
            "internalType":"string",
            "name":"",
            "type":"string"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"totalSupply",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"tradingFeeReceiver2",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"from",
            "type":"address"
         },
         {
            "internalType":"address",
            "name":"to",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"transferFrom",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
         }
      ],
      "name":"transferOwnership",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"unpause",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
         }
      ],
      "name":"upgrade",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         }
      ],
      "name":"upgradeTo",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   },
   {
      "inputs":[
         {
            "internalType":"address",
            "name":"newImplementation",
            "type":"address"
         },
         {
            "internalType":"bytes",
            "name":"data",
            "type":"bytes"
         }
      ],
      "name":"upgradeToAndCall",
      "outputs":[
         
      ],
      "stateMutability":"payable",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"validator",
      "outputs":[
         {
            "internalType":"address",
            "name":"",
            "type":"address"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[
         
      ],
      "name":"withdrawSaleToken",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
]