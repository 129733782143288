export const RECAPTCHA_SITE_KEY = '6LfSmM4fAAAAAETBuy_9TKYFG_RyIZCicijFLYod';

export const NORMAL_BOX_PRICE_BUSD = 20
export const PREMIUM_BOX_PRICE_BUSD = 100
export const ULTRA_BOX_PRICE_BUSD = 500

export const BOMB_BOX_PRICE_MTB = 1000
export const BOMB_BOX_END_TIME = 1654437600

export const HERO_LEVEL_UP_FEE = [200, 600, 2000, 6000, 20000, 60000];
export const HERO_FUSION_FEE = [500, 2500, 12500, 62500, 312500];
export const BOMB_FUSION_FEE_MTB = [300, 1500, 6000, 10000, 15000];
export const BOMB_FUSION_FEE_BRICK = [100, 200, 600, 1000, 1500];
export const BOMB_FUSION_PROBABILITY = [90, 75, 60, 45, 30];

export const ERROR_TYPE_TRANSACTION = 1
export const TEST_NET = false
export const METAMASK_PROVIDER = 'METAMASK'
export const WALLETCONNECT_PROVIDER = 'WALLETCONNECT'
export const NETWORK_VERSION = TEST_NET ? 97 : 56
export const NETWORK_VERSION_HEX = TEST_NET ? '0x61' : '0x38'
export const MTB_CONTRACT_ADDRESS = TEST_NET ? "0x8B9f24fE830Aa344db98bB6cC0f690d6650D8ad3" : "0x2baD52989Afc714C653da8e5C47bF794A8f7b11D";
export const BUSD_CONTRACT_ADDRESS = TEST_NET ? "0xdB6DDE3C43863D4363681bc63df90DF123fD52D4" : "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
// 0xfCc9FF37eEc9aF7B62B35F514caed747B94314bA
export const STAKE_NORMAL_BOX_CONTRACT_ADDRESS = TEST_NET ? "0xF54A32E0312E19dF6D1665Cdc9582718B346a8F1" : "0x1F36BEf063Ee6FCEFEcA070159D51a3B36BC68d6";
export const STAKE_PREMIUM_BOX_CONTRACT_ADDRESS = TEST_NET ? "0x5F67959a0Ef2A5A8ad0fF780516aA8043FDdA033" : "0x2076626437c3Bb9273998A5E4F96438aBE467F1C";
export const STAKE_ULTRA_BOX_CONTRACT_ADDRESS = TEST_NET ? "0x5c227Ecb55950Ecae1c6C43678a5621F1D941385" : "0x9341FAED0b86208c64ae6F9d62031B1f8A203240";
export const STAKE_BOMB_BOX_CONTRACT_ADDRESS = TEST_NET ? "0x9663D121EBf000d72d4692cb3FA00Eb18b7b9c49" : "0x4228eEc897BFdf3840a0ACbeB74aD5412a870A00";

// --------------------------------------------------------------------------------------------------
export const PRIZE_POOL_MARKET_ADDRESS = TEST_NET ? "0xfCc9FF37eEc9aF7B62B35F514caed747B94314bA" : "0xD084261fEB546EE9102d995A45c5677DB59229b8";

// ONLY - TESTNET !!! -------------------------------------------------------------------------------
export const NORMAL_BOX_PRICE_MTB = 4000
export const PREMIUM_BOX_PRICE_MTB = 10000
// export const ULTRA_BOX_PRICE_MTB = 50000
export const ULTRA_BOX_PRICE_MTB = 4000

export const FEE_RESET_HERO = 15

export const CHANGE_SKIN_HERO_FEE = [200, 600, 2000, 6000, 20000, 60000]
export const BOMB_SKIN_HERO_FEE = [200, 300, 500, 700, 1000, 2000]

export const FAUCET_CONTRACT_MTB_ADDRESS = '0x8aF8344f9918Bb6e2FC97562fCD849b0160CD3dE'; // ok
export const FAUCET_CONTRACT_BUSD_ADDRESS = '0x4394d42a94cEEe5d1695cdf3ABF40bc45b325556'; // ok

export const NFT_HERO_CONTRACT_ADDRESS = TEST_NET ? '0x261e3b1B4056C7D041dc33d9982F5b94589D096e' : '0x05F0d89931eb06D4596AF209de4A9779cEF73cDE';
export const NFT_BOMB_CONTRACT_ADDRESS = TEST_NET ? '0x8571CA4380655D4C0A4CD253c29469DeE4eE7c36' : '0xaAF54a3dC073E5c8D7f03c21C3672DeB118D3514';
// export const NFT_BOMB_CONTRACT_ADDRESS = TEST_NET ? '0x8571CA4380655D4C0A4CD253c29469DeE4eE7c36' : '0x8571CA4380655D4C0A4CD253c29469DeE4eE7c36';

export const CLAIM_MTB_CONTRACT_ADDRESS = TEST_NET ? '0xa8D7c7d10c3AAd6d0F9Cc0F63b46E7C317bCa4eb' : '0xa5AE64799339e9f00326A33278A31f0E73DFBdf3';
export const BRICK_CONTRACT_ADDRESS = TEST_NET ? '0x94766cF528F420e734F970B87c27e08D2379C46F' : '0x4169B51B5907968aCA1DD2A9875500d9C40E4950';

export const STAKE_MYSTERY_BOX_CONTRACT_ADDRESS = TEST_NET ? '0x696A6aC1D0C427756bBE49eFDdE4f04476e63fcE' : '0xc04b4019fAF818d9febC752D933f5362C85c9eE3';

// export const NFT_HOUSE_CONTRACT_ADDRESS = TEST_NET ? '0xA14cDF608eeD9bf8BB98e9c4Dc971f32cEDa41AF' : '0xA14cDF608eeD9bf8BB98e9c4Dc971f32cEDa41AF';
export const NFT_HOUSE_CONTRACT_ADDRESS = TEST_NET ? '0x1A65fcEcd2743944bDB0AF167e60fF1BD84F3282' : '0x687326023128C959E6422aFAB52481D20D10cbd3';

// 0xE0de01Cc2764D4f6f8b364341da7EE3291337C6a
// 0xfCc9FF37eEc9aF7B62B35F514caed747B94314bA

// --------------------------------------------------------------------------------------------------

export const NETWORK_CONFIG = TEST_NET ?
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency:
        {
            name: 'tBNB',
            symbol: 'tBNB',
            decimals: 18
        },
        rpcUrls: ['https://speedy-nodes-nyc.moralis.io/5274a4ada7cd449368bf2c47/bsc/testnet'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
    }]
    :
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency:
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed1.binance.org'],
        blockExplorerUrls: ['https://bscscan.com'],
    }]

export const CLAIM_CONFIG = {
    MTB: {
        min: 10000,
        max: 50000,
        gap: 168,
        need_mtb : 1000
    },
    COMMON_BOX: {
        min: 1,
        max: 10,
        gap: 24,
        need_mtb : 1000
    },
    PREMIUM_BOX: {
        min: 1,
        max: 5,
        gap: 24,
        need_mtb : 1000
    },
    ULTRA_BOX: {
        min: 1,
        max: 2,
        gap: 24,
        need_mtb : 1000
    },
    BOMB_BOX: {
        min: 1,
        max: 25,
        gap: 24,
        need_mtb : 1000
    },
    BRICK: {
        min: 100,
        max: 1000,
        package: [100,1000],
        gap: 1,
        need_mtb : 1000
    },
}

export const FEE_UNLOCK = {
    HERO: [400, 1500, 6000, 50000, 500000],
    BOMB: [100, 300, 1000, 3000, 10000],
    HOUSE:  [400, 1500, 6000, 50000, 500000],
}

export const ITEM_TYPE = {
    BRICK: 0,
}
