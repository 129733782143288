import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import useCollapseDrawer from '../hooks/useCollapseDrawer'
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const { isCollapse } = useCollapseDrawer();
  const isDesktop = useResponsive('up', 'lg');

  const logo = (
      <Stack alignItems="center" justifyContent="center"  sx={{ width: isCollapse || !isDesktop ? 64 : 96, height: 48, ...sx}}>
        <img
          src={`/logo/metabomb-logo.png?w=164&h=164&fit=crop&auto=format`}
          alt="i"
          loading="lazy"
        />
      </Stack>
  );
  
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
