import gql from 'graphql-tag';

export const RANDOM = gql`
query {
  random 
}`;

export const SERVER_TIME = gql`
query {
  server_time 
}`;


export const QUERY_CONFIG = gql`
query market_config($key: String) {
  market_config(key:$key){
    k
    v
  }
}`;

export const QUERY_ME = gql`
query {
  me {
  id
  user_name
  name
  map_count
  mtb_num
  mystery_fragment_num
  brick_num
  box_common_num
  box_premium_num
  box_ultra_num
  box_bomb_num
  box_mystery_num
  banned_at
  banned_until
  banned_message
  enable_2fa
  last_reset_at
  created_at
  last_claim_mtb
  last_claim_brick
  last_claim_box_common
  last_claim_box_premium
  last_claim_box_ultra
  last_claim_box_bomb
  last_claim_mtb_log {
      time
      amount
      status
    }
  last_claim_brick_log {
      time
      amount
      status
    }
  last_claim_box_common_log {
      time
      amount
      status
    }
  last_claim_box_premium_log {
      time
      amount
      status
    }
  last_claim_box_ultra_log {
      time
      amount
      status
    }
  last_claim_box_bomb_log {
      time
      amount
      status
    }
  }
}`;

export const RESET_MAP = gql`
mutation {
  reset {
    error,
    message
  }
}`;

export const CLAIM = gql`
mutation claim ($amount: Int!, $assert_type: Int!){
  claim (amount: $amount, assert_type: $assert_type ) {
    error
    message
  	timestamp
    sign
    log_id
    amount
  }
}`;

export const CLAIM_IN_GAME_BOX = gql`
mutation claimBoxInGame ($amount: Int!, $assert_type: Int!){
  claimBoxInGame (amount: $amount, assert_type: $assert_type ) {
    error
    message
  	timestamp
    sign
    log_id
    amount
  }
}`;

export const DEPOSIT = gql`
mutation deposit ($amount: Int!){
  deposit (amount: $amount) {
    error
    message
  	timestamp
    sign
  }
}`;

export const DEPOSIT_BRICK = gql`
mutation deposit_brick ($amount: Int!){
  deposit_brick (amount: $amount) {
    error
    message
  	timestamp
    sign
  }
}`;

export const JOIN = gql`
mutation join ($input: JoinInput!){
  join (input: $input) {
    error
    message
  	token
    id
    user_name
  }
}`;

export const REGISTER = gql`
mutation register ($input: RegisterInput!){
  register (input: $input) {
    error
    message
  	token,
    user_name
  }
}`;

export const SET_2FA = gql`
mutation set_2fa ($on: Boolean!){
  set_2fa (on: $on) {
    error
    message
    qrimage
  }
}`;

export const VERIFY_2FA = gql`
mutation verify_2fa ($otp: String!){
  verify_2fa (otp: $otp) {
    error
    message
  }
}`;

export const MY_ACTIVITIES = gql`
query {
  me {
    activities {
      message
      create_at
    }
  }
}`;

export const CHANGE_PASSWORD = gql`
mutation change_password ($input: ChangePasswordInput!){
  change_password (input: $input) {
    error
    message
  }
}`;

export const CHANGE_NAME = gql`
mutation change_name ($input: ChangeNameInput!){
  change_name (input: $input) {
    error
    message
  }
}`;

export const CREATE_ACTIVITY = gql`
mutation create_activity ($message: String!){
  create_activity (message: $message) {
    error
  }
}`;

export const HERO_CHANGE_NAME = gql`
mutation hero_change_name ($hero_id: Int!, $name: String!){
  hero_change_name (hero_id: $hero_id, name: $name) {
    error
    message
  }
}`;

export const HOUSE_CHANGE_NAME = gql`
mutation house_change_name ($house_id: Int!, $name: String!){
  house_change_name (house_id: $house_id, name: $name) {
    error
    message
  }
}`;

export const BOMB_CHANGE_NAME = gql`
mutation bomb_change_name ($bomb_id: Int!, $name: String!){
  bomb_change_name (bomb_id: $bomb_id, name: $name) {
    error
    message
  }
}`;

export const HERO_RESET = gql`
mutation reset_hero ($hero_id: Int!){
  reset_hero (hero_id: $hero_id) {
    error, 
    message
  }
}`;

export const HERO_UNLOCK = gql`
mutation unlock_hero ($hero_id: Int!){
  unlock_hero (hero_id: $hero_id) {
    error, 
    message
  }
}`;

export const BOMB_UNLOCK = gql`
mutation unlock_bomb ($bomb_id: Int!){
  unlock_bomb (bomb_id: $bomb_id) {
    error, 
    message
  }
}`;

export const PUT_ON_SALE_BOX = gql`
mutation put_on_sale_box ($input: BoxSaleInput!){
  put_on_sale_box (input: $input) {
    error
    message
    age
    timestamp
    signature
    in_game_process
  }
}`;

export const PUT_ON_SALE_HERO = gql`
mutation put_on_sale_hero ($input: SaleInput!){
  put_on_sale_hero (input: $input) {
    error
    message
    age
    timestamp
    signature
    in_game_process
  }
}`;

export const PUT_ON_SALE_BOMB = gql`
mutation put_on_sale_bomb ($input: SaleInput!){
  put_on_sale_bomb (input: $input) {
    error
    message
    timestamp
    signature
    in_game_process
  }
}`;

export const PUT_ON_SALE_ITEM = gql`
mutation put_on_sale_item ($input: SaleInput!){
  put_on_sale_item (input: $input) {
    error
    message
    timestamp
    signature
    in_game_process
  }
}`;

export const PUT_ON_SALE_HOUSE = gql`
mutation put_on_sale_house ($input: SaleInput!){
  put_on_sale_house (input: $input) {
    error
    message
    lockUntil
    timestamp
    signature
    in_game_process
  }
}`;

export const PURCHASE_SALE_BOX = gql`
mutation purchase_sale_box ($input: BoxSaleInput!){
  purchase_sale_box (input: $input) {
    error
    message
    age
    timestamp
    signature
    in_game_process
  }
}`;

export const PURCHASE_SALE_HERO = gql`
mutation purchase_sale_hero ($input: SaleInput!){
  purchase_sale_hero (input: $input) {
    error
    message
    age
    timestamp
    signature
    in_game_process
  }
}`;

export const PURCHASE_SALE_BOMB = gql`
mutation purchase_sale_bomb ($input: SaleInput!){
  purchase_sale_bomb (input: $input) {
    error
    message
    age
    timestamp
    signature
    in_game_process
  }
}`;

export const OPEN_BOX_HERO = gql`
mutation open_box_hero ($input: OpenBoxInput!){
  open_box_hero (input: $input) {
    error
    message
    timestamp
    in_game_process
    new_assert_id
  }
}`;

export const OPEN_BOX_BOMB = gql`
mutation open_box_bomb ($input: OpenBoxInput!){
  open_box_bomb (input: $input) {
    error
    message
    timestamp
    in_game_process
    new_assert_id
  }
}`;

// export const QUERY_HEROES = gql`
// query {
//   heroes {
//     id
//     name
//     display_id
//     rarity
//     level
//     hero_class
//     for_sale
//     price
//   }
// }`;

export const QUERY_HERO = gql`
query hero($id: ID!) {
  hero(id:$id){
    id
    user_id
    name
    display_id
    rarity
    level
    hero_class
    for_sale
    price
    price_set_at
    power
    health
    speed
    stamina
    bomb_num
    bomb_range
    lock_ui
    lock_until
    is_in_game
    user {
      id
      name
      user_name
    }
    hero_status {
      age
      mana
      state
    }
    upgrade_history {
      upgrade_at
      upgrade_type
      token_id
      token_material_id
      level
      rarity
    }
  }
}`;

export const QUERY_HEROES_MARKET = gql`
query hero_market($input: HeroMarketInput!) {
  hero_market(input:$input){
    error
    count
    heroes {
      id
      name
      display_id
      rarity
      level
      hero_class
      for_sale
      price
      price_set_at
      is_in_game
    }
  }
}`;

export const QUERY_HEROES_USER = gql`
query hero_user($id:String, $input: HeroMarketInput!) {
  hero_user(id:$id, input:$input){
    error
    count
    heroes {
      id
      name
      display_id
      rarity
      level
      hero_class
      for_sale
      price
      price_set_at
      is_in_game
    }
  }
}`;

export const QUERY_HEROES_ME = gql`
query hero_me($input: HeroMarketInput!) {
  hero_me(input:$input){
    error
    count
    heroes {
      id
      name
      display_id
      rarity
      level
      hero_class
      for_sale
      price
      price_set_at
      is_in_game
      hero_status {
        age
        mana
        state
      }
      lock_ui
    }
  }
}`;

export const QUERY_BOMB = gql`
query bomb($id: ID!) {
  bomb(id:$id){
    id
    user_id
    name
    rarity
    skill_1
    skill_2
    skill_3
    skill_4
    skill_5
    skill_6
    display_id
    element
    for_sale
    price
    price_set_at
    lock_ui
    lock_until
    is_in_game
    user {
      id
      name
      user_name
    }
    upgrade_history {
      upgrade_at
      token_id
      token_material_id
      rarity
    }
  }
}`;

export const QUERY_BOMBS_MARKET = gql`
query bomb_market($input: BombMarketInput!) {
  bomb_market(input:$input){
    error
    count
    bombs {
      id
      name
      rarity
      skill_1
      skill_2
      skill_3
      skill_4
      skill_5
      skill_6
      display_id
      element
      for_sale
      price
      price_set_at
      hero_used
      is_in_game
    }
  }
}`;

export const QUERY_BOMBS_USER = gql`
query bomb_user($id:String, $input: BombMarketInput!) {
  bomb_user(id:$id, input:$input){
    error
    count
    bombs {
      id
      name
      rarity
      skill_1
      skill_2
      skill_3
      skill_4
      skill_5
      skill_6
      display_id
      element
      for_sale
      price
      price_set_at
      hero_used
      is_in_game
    }
  }
}`;

export const QUERY_BOMBS_ME = gql`
query bomb_me($input: BombMarketInput!) {
  bomb_me(input:$input){
    error
    count
    bombs {
      id
      name
      rarity
      skill_1
      skill_2
      skill_3
      skill_4
      skill_5
      skill_6
      display_id
      element
      for_sale
      price
      price_set_at
      hero_used
      lock_ui
      is_in_game
    }
  }
}`;

export const QUERY_BOXES_USER = gql`
query box_user($id: String, $input: BoxMarketInput!) {
  box_user(id: $id, input:$input){
    error
    count
    boxes {
      id
      token_id
      user {
        id
        name
      }
      box_type
      price
      price_set_at
      for_sale
      chain_process
      is_in_game
    }
  }
}`;

export const QUERY_BOXES_MARKET = gql`
query box_market($input: BoxMarketInput!) {
  box_market(input:$input){
    error
    count
    boxes {
      id
      token_id
      user {
        id
        name
      }
      box_type
      price
      price_set_at
      for_sale
      chain_process
      is_in_game
    }
  }
}`;

export const QUERY_BOXES_ME = gql`
query box_me($input: BoxMarketInput!) {
  box_me(input:$input){
    error
    count
    boxes {
      id
      token_id
      user {
        id
        name
      }
      box_type
      price
      price_set_at
      for_sale
      chain_process
      is_in_game
    }
  }
}`;


export const QUERY_ITEMS_USER = gql`
query item_user($id: String, $input: ItemMarketInput!) {
  item_user(id: $id, input:$input){
    error
    count
    items {
      id
      user {
        id
        name
      }
      item_type
      quantity
      sale_no
      for_sale
      price
      price_set_at
    }
  }
}`;

export const QUERY_ITEMS_MARKET = gql`
query item_market($input: ItemMarketInput!) {
  item_market(input:$input){
    error
    count
    items {
      id
      user {
        id
        name
      }
      item_type
      quantity
      sale_no
      for_sale
      price
      price_set_at
    }
  }
}`;

export const QUERY_ITEMS_ME = gql`
query item_me($input: ItemMarketInput!) {
  item_me(input:$input){
    error
    count
    items {
      id
      user {
        id
        name
      }
      item_type
      quantity
      sale_no
      for_sale
      price
      price_set_at
    }
  }
}`;


export const UPDATE_PROCESS = gql`
mutation chain_process ($id: ID!){
  chain_process (id: $id) 
}`;

export const QUERY_SOLD_HISTORY = gql`
query sold_history_nft($token_id: Int!,$token_type: Int! ) {
  sold_history_nft(token_id:$token_id, token_type:$token_type){
    seller_id
    seller_addr
    buyer_id
    buyer_addr
    price
    created_at
  }
}`;


export const QUERY_SOLD_CHART = gql`
query sold_history_chart($time: String!) {
  sold_history_chart(time:$time){
    nodes {
      quantity
      volume
      highest
      timeline
    }
  }
}`;

export const QUERY_SOLD_STATISTIC = gql`
query sold_history_statistic($time: String!) {
  sold_history_statistic(time:$time){
    total_sale
    total_volume
    highest_price
  }
}`;

export const QUERY_RANK_MTB = gql`
query {
  rank_mtb {
    rank
    name
    address
    mtbBlance
    mapCount
  }
}`;

export const QUERY_RANK_FUSION_BOMB = gql`
query {
  rank_fusion_bomb {
    rank
    name
    address
    point
    fusionCount
  }
}`;

export const QUERY_RESET_MAP_INFO = gql`
query {
  last_reset_map_info {
    error
    countReset
    lastMapCount
  }
}`;

export const HERO_LOCK_UI = gql`
mutation hero_lock_ui ($hero_id: Int!, $lock: Int){
  hero_lock_ui (hero_id: $hero_id, lock: $lock) {
    error
    message
  }
}`;

export const HERO_UPGRADE_STATE = gql`
mutation hero_upgrade_state ($hero_id: Int!, $state: Int){
  hero_upgrade_state (hero_id: $hero_id, state: $state) {
    error
    message
  }
}`;

export const HERO_FUSION = gql`
mutation fusion_hero ($input: UpgradeInput!){
  fusion_hero (input: $input) {
    error
    message
    timestamp
    signature
    hero_id1
    rarity1
    level1
    lock_until1
    hero_id2
    rarity2
    level2
    lock_until2
    in_game_process
  }
}`;

export const HERO_LEVEL_UP = gql`
mutation level_up_hero ($input: UpgradeInput!){
  level_up_hero (input: $input) {
    error
    message
    timestamp
    signature
    hero_id1
    rarity1
    level1
    lock_until1
    hero_id2
    rarity2
    level2
    lock_until2
    in_game_process
  }
}`;

export const QUERY_HOUSE = gql`
query house($id: ID!) {
  house(id:$id){
    id
    name
    user_id
    level
    for_sale
    price
    price_set_at
    durability
    is_active
    lock_until
    user {
      id
      name
      user_name
    }
  }
}`;

export const QUERY_HOUSES_MARKET = gql`
query house_market($input: HouseMarketInput!) {
  house_market(input:$input){
    error
    count
    houses {
      id
      name
      level
      durability
      is_active
      for_sale
      price
      price_set_at
    }
  }
}`;

export const QUERY_HOUSES_USER = gql`
query house_user($id: String, $input: HouseMarketInput!) {
  house_user(id:$id, input:$input){
    error
    count
    houses {
      id
      name
      level
      durability
      is_active
      for_sale
      price
      price_set_at
    }
  }
}`;

export const QUERY_HOUSES_ME = gql`
query house_me($input: HouseMarketInput!) {
  house_me(input:$input){
    error
    count
    houses {
      id
      name
      level
      for_sale
      durability
      is_active
      price
      price_set_at
    }
  }
}`;

export const HOUSE_REPAIR = gql`
mutation repair_house ($house_id: Int!){
  repair_house (house_id: $house_id) {
    error
    message
  }
}`;

export const BOMB_FUSION = gql`
mutation fusion_bomb ($input: UpgradeInput!){
  fusion_bomb (input: $input) {
    error
    message
    timestamp
    signature
    hero_id1
    rarity1
    lock_until1
    hero_id2
    rarity2
    lock_until2
    in_game_process
    result
  }
}`;

export const BOMB_UPGRADE_STATE = gql`
mutation bomb_upgrade_state ($bomb_id: Int!, $state: Int){
  bomb_upgrade_state (bomb_id: $bomb_id, state: $state) {
    error
    message
  }
}`;

export const CHANGE_SKIN_HERO = gql`
mutation change_skin_hero ($hero_id: Int!){
    change_skin_hero (hero_id: $hero_id) {
        error
        message
    }
}`;

export const CHANGE_SKIN_BOMB = gql`
mutation change_skin_bomb ($bomb_id: Int!){
    change_skin_bomb (bomb_id: $bomb_id) {
        error
        message
    }
}`;