// export const BASE_API = "http:///127.0.0.1:5000"

export const BASE_API = "https://api.metabomb.io";
export const GRAPH_API = `${BASE_API}/graphql`;
console.log();
export const linkToHome = "/";

export const linkToMarket = "/trade/heroes";

export const linkToAccount = "/account/settings";

export const linkToInventory = "/account/inventory";

export const linkToClaim = "/account/claim";

export const linkToActivites = "/account/activity";
